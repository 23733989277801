import React from "react";

// TODO: 보강
const NotFoundPage = () => (
  <>
    <h1 style={{ color: "#fff" }}>NOT FOUND</h1>
  </>
);

export default NotFoundPage;
